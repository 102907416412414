import type { PaginateResult } from "mongoose"

export interface GeneratedCostSpecification {
  _id: string
  file: FileData
  totalCosts: number
  invoicePeriod?: string
}

export interface CostSpecification {
  _id: string
  updatedAt: string
  createdAt: string
  organization: string
  description: string
  remark?: string
  costOrganization: {
    _id: string
    description: string
  }
  contract: {
    _id: string
    description: string
  }
  tickets: Array<Ticket>
  specifications: Array<GeneratedCostSpecification>
}

export interface CostSpecificationCreate {
  costOrganization: string
  contract?: string
  description: string
  remark?: string
}

export interface CostSpecificationUpdate extends CostSpecificationCreate { }

export interface CostSpecificationPdf {
  invoicePeriod?: string
  description: string
  remark?: string
 }

export const useCostSpecificationStore = defineStore("cost-specification", () => {
  const _baseUrl = "/cost-specifications"

  const getByPage = async (queryParameters: Record<string, any> = {}): Promise<PaginateResult<CostSpecification>> => {
    const query = getUrlSearchParams(queryParameters)

    const { data } = await useSamApi<PaginateResult<CostSpecification>>(`${ _baseUrl }?${ query }`)
    return data
  }

  const getById = async (id: string): Promise<CostSpecification> => {
    const { data } =  await useSamApi<CostSpecification>(`${ _baseUrl }/${ id }`)
    return data
  }

  const create = async (body: CostSpecificationCreate): Promise<CostSpecification> => {
    const { data } = await useSamApi<CostSpecification>(_baseUrl, {
      method: "POST",
      body: JSON.stringify(body)
    })

    return data
  }

  const update = async (id: string, body: CostSpecificationUpdate): Promise<CostSpecification> => {
    const { data } = await useSamApi<CostSpecification>(`${ _baseUrl }/${ id }`, {
      method: "PUT",
      body: JSON.stringify(body)
    })

    return data
  }

  const updateTickets = async (id: string, tickets: Array<string>): Promise<CostSpecification> => {
    const { data } = await useSamApi<CostSpecification>(`${ _baseUrl }/${ id }/tickets`, {
      method: "PUT",
      body: JSON.stringify(tickets)
    })

    return data
  }

  const createPdf = async (id: string, body: CostSpecificationPdf): Promise<GeneratedCostSpecification> => {
    const { data } = await useSamApi<GeneratedCostSpecification>(`${ _baseUrl }/${ id }/create-pdf`, {
      method: "POST",
      body: JSON.stringify(body)
    })

    return data
  }

  const deletePdf = async (id: string, specificationId: string): Promise<GeneratedCostSpecification> => {
    const { data } = await useSamApi<GeneratedCostSpecification>(`${ _baseUrl }/${ id }/delete-pdf/${ specificationId }`, {
      method: "DELETE"
    })

    return data
  }

  return {
    getByPage,
    getById,

    create,
    createPdf,
    deletePdf,
    update,
    updateTickets
  }
})